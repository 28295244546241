import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { ComponentMap } from '../utils/componentMap'
import Header from '../components/header'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const heroContent = get(this, 'props.data.contentfulHeader')
    const featuredContent = get(this, 'props.data.contentfulFeatured')
    return (
      <Layout location={this.props.location}>
        <div className={'pageContent'}>
          <Helmet title={siteTitle || 'Zonta Club of Toronto'} />
          <Header
            backgroundVideo={heroContent.heroVideo}
            backgroundImage={heroContent.heroImage}
            title={heroContent.title}
            description={heroContent.description}
            ctas={heroContent.links}
            layout={'homepage'}
          />
          <div className="wrapper">
            {featuredContent.description && (<div
              className="body-markup"
              dangerouslySetInnerHTML={{
                __html: featuredContent.description.childMarkdownRemark.html,
              }}
            />)}
            {featuredContent.pageContent && featuredContent.pageContent.map(content => {
              const Component = ComponentMap.find(component => content.__typename === component.type).component;
              return <Component content={content} />
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHeader(contentful_id: {eq: "14lV8mQyg9wMoWunBhU7SU"}) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        description
        fluid {
          src
          srcSetWebp
          srcSet
        }
        file {
          contentType
        }
      }
      heroVideo {
        file {
          url
          contentType
        }
        description
        title
      }
      links {
        ... on ContentfulContentPage {
          id
          slug
          title
          navigationLabel
          parentPage {
            slug
          }
        }
        ... on ContentfulScholarshipAward {
          title
          navigationLabel
          slug
          id
        }
        ... on ContentfulProject {
          title
          navigationLabel
          slug
          id
        }
        ... on ContentfulEvent {
          title
          navigationLabel
          slug
          id
        }
        ... on ContentfulExternalLink {
          id
          appearance
          title
          openInNewTab
          url
          parentPage {
            slug
          }
          image: image {
            description
            fluid {
              srcWebp
              src
              srcSetWebp
              srcSet
            }
          }
        }
      }
    }
    contentfulFeatured(contentful_id: {eq: "4XZfyRqELCcyhp0jqNNry2"}) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      pageContent {
        ... on ContentfulScholarshipAward {
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            description
            fluid {
              src
              srcSetWebp
              srcSet
            }
            file {
              contentType
            }
          }
          title
          slug
          zontaInternational
        }
        ... on ContentfulProject {
          description {
            childMarkdownRemark {
              html
            }
          }
          address
          websiteUrl
          title
          slug
          phoneNumber
          heroImage {
            description
            fluid {
              src
              srcSetWebp
              srcSet
            }
            file {
              contentType
            }
          }
          event {
            title
            slug
            locationName {
              childMarkdownRemark {
                html
              }
            }
            dateTime
            cost
            url
          }
        }
        ... on ContentfulContentPage {
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            description
            fluid {
              src
              srcSetWebp
              srcSet
            }
            file {
              contentType
            }
          }
          parentPage {
            slug
          }
          title
          slug
        }
        ... on ContentfulEvent {
          title
          slug
          locationName {
            childMarkdownRemark {
              html
            }
          }
          dateTime
          cost
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            description
            fluid {
              src
              srcSetWebp
              srcSet
            }
            file {
              contentType
            }
          }
        }
      }
    }
  }
`
